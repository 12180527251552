<template>
  <div class="container mx-2">
    <!-- Breadcrumb  -->
    <va-card>
      <va-card-content>
        <div style="display: flex">
          <va-breadcrumbs color="primary">
            <va-breadcrumbs-item :label="$t('menu.users')" />
            <va-breadcrumbs-item :label="$t('menu.organizers')" />
          </va-breadcrumbs>
        </div>
      </va-card-content>
    </va-card>
    <div class="mt-3">
      <div class="flex">
        <va-card stripe stripe-color="primary">
          <va-card-content>
            <h1
              v-if="organizers.length == 0"
              class="row align--center justify--center"
              style="font-size: 32px; height: 35vh"
            >
              {{ $t("no_organizers") }}
              <va-icon class="material-icons" size="32px"> schedule </va-icon>
            </h1>
            <va-list v-else>
              <va-list-item
                v-for="(organizer, index) in organizers"
                :key="organizer.name"
                to=""
              >
                <strong>{{ index + offset + 1 }}.</strong>
                <va-list-item-section avatar class="px-3">
                  <va-avatar>
                    <img v-if="organizer.img" :src="organizer.img" />
                    <div v-else>{{ organizer.name.substring(0, 1) }}</div>
                  </va-avatar>
                </va-list-item-section>

                <va-list-item-section>
                  <va-list-item-label>
                    {{ organizer.name }}
                  </va-list-item-label>

                  <va-list-item-label caption :lines="index + 1">
                    {{ organizer.tel }}
                  </va-list-item-label>

                  <va-list-item-label caption :lines="index + 1">
                    {{ organizer.email }}
                  </va-list-item-label>
                </va-list-item-section>

                <va-list-item-section icon>
                  <va-icon name="remove_red_eye" color="gray" />
                </va-list-item-section>
              </va-list-item>
            </va-list>
            <va-pagination
              v-if="pages > 1"
              color="#0a4695"
              style="direction: ltr"
              class="float-end"
              v-model="currentPage"
              :visible-pages="3"
              :pages="pages"
            />
          </va-card-content>
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>
import { request, gql } from "graphql-request";
export default {
  data() {
    return {
      organizers: [],
      organizersCount: null,
      pages: null,
      currentPage: 1,
      limit: 10,
      offset: 0,
      loading: true,
    };
  },
  async created() {
    // Pagination Logic
    try {
      const ORGANIZERS_COUNT = gql`
        query {
          UsersHasChamps {
            id
          }
        }
      `;
      const counterResponse = await request(
        this.$store.state.appUrl,
        ORGANIZERS_COUNT
      );
      this.organizersCount = counterResponse.UsersHasChamps.length;
      this.pages = Math.ceil(this.organizersCount / this.limit);
      this.getorganizers();
    } catch (error) {
      this.loading = false;
      console.log(error);
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("errors.network"),
          text: "Try Again in a minute",
          confirmButtonColor: "#023b81",
          confirmButtonText: "Reload",
        })
        .then((result) => {
          if (result.value) {
            location.reload();
          }
        });
    }
  },
  methods: {
    async getorganizers() {
      this.offset = (this.currentPage - 1) * this.limit;
      const GET_organizerS = gql`
        query {
          organizers: UsersHasChamps {
            id
            name
          }
        }
      `;
      try {
        const response = await request(
          this.$store.state.appUrl,
          GET_organizerS
        );
        this.organizers = response.organizers;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$swal
          .fire({
            icon: "warning",
            title: this.$t("errors.network"),
            text: "Try Again in a minute",
            confirmButtonColor: "#023b81",
            confirmButtonText: "Reload",
          })
          .then((result) => {
            if (result.value) {
              location.reload();
            }
          });
      }
    },
  },
  watch: {
    async currentPage() {
      await this.getorganizers();
    },
  },
};
</script>

<style>
.va-list-item-section {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
</style>
